<template>
  <fragment>
    <div v-if="requestSent" class="alert alert-success">
      Thank You! An email has been sent out with a link reset your password.
    </div>

    <div v-if="!isUserRequestValid" class="alert alert-danger">
      Oops! We cannot find an account with that email address.
    </div>

    <form class="d-flex flex-column" v-on:submit.prevent="onSubmit">
      <h2 class="h4 mb-3">Reset Password</h2>

      <div class="form-group control">
        <label for="email">Email</label>
        <input id="email" name="email" type="text" class="form-control" :class="{ 'is-invalid': errors.has('email') }"
          autocomplete="off" placeholder="Please enter email" v-validate="'required|email'" v-model="forgotpassword.email" data-vv-as="Email" />
        <span class="invalid-feedback">{{ errors.first('email') }}</span>
      </div>

      <button type="submit" class="btn btn-success ld-ext-right" :class="{ 'running' : isProcessing }" :disabled="isProcessing">
        Submit
        <div class="ld ld-ring ld-spin"></div>
      </button>

      <router-link to="/login" class="font-weight-medium text-center d-block">Back To Login</router-link>
    </form>
  </fragment>
</template>

<script>
import { UserService } from "@/services/"

export default {
  name: 'forgot-password',
  data: function() {
    return {
      isProcessing: false,
      requestSent: false,
      isUserRequestValid: true,
      forgotpassword: {
        email: null
      }
    };
  },
  methods: {
    async onSubmit() {
      this.isProcessing = true;
      this.isUserRequestValid = true;

      var isFormValid = await this.$validator.validate();
      if (!isFormValid) {
        return;
      }

      return UserService.forgotPassword(this.forgotpassword)
        .then(() => {
          this.isProcessing = false;
          this.requestSent = true
        })
        .catch(err => {
          this.isProcessing = false;
          if (err.response.data.message && err.response.data.modelState) {
            this.isUserRequestValid = false;
          }
        });
    }
  }
};
</script>
